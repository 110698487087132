<template>
    <nav class="bg-surface fixed top-0 z-50 w-full border-b border-gray-200 min-h-20" role="navigation">
        <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 h-[75px]">
            <div class="flex justify-between items-center h-[75px]">
                <!-- Logo -->
                <div class="flex-shrink-0 flex items-center">
                    <Link href="/" aria-label="Home">
                        <img
                            loading="lazy"
                            class="h-20"
                            height="160"
                            width="160"
                            :src="'/images/logo_webplogo.webp'"
                            alt="Afla pe cine votezi Logo"
                        />
                    </Link>
                </div>

                <!-- Desktop Navigation -->
                <div class="hidden md:flex md:items-center md:space-x-4">
                    <Link
                        href="/"
                        class="px-3 py-2 rounded-md text-sm font-medium"
                        :class="{ 'bg-main text-white': $page.url === '/' }"
                        aria-label="Acasă"
                    >
                        Ştiri
                    </Link>
                    <Link
                        href="/politicieni"
                        class="px-3 py-2 rounded-md text-sm font-medium"
                        :class="{ 'bg-main text-white': $page.url === '/politicieni' }"
                        aria-label="Politicieni"
                    >
                        Politicieni
                    </Link>
                    <Link
                        href="/publicatii"
                        class="px-3 py-2 rounded-md text-sm font-medium"
                        :class="{ 'bg-main text-white': $page.url === '/publicatii' }"
                        aria-label="Publicații"
                    >
                        Publicații
                    </Link>
                    <Link
                        href="/despre"
                        class="px-3 py-2 rounded-md text-sm font-medium"
                        :class="{ 'bg-main text-white': $page.url === '/despre' }"
                        aria-label="Despre"
                    >
                        Despre
                    </Link>
                    <Link
                        href="/cum-functioneaza"
                        class="px-3 py-2 rounded-md text-sm font-medium"
                        :class="{ 'bg-main text-white': $page.url === '/cum-functioneaza' }"
                        aria-label="Metodologie"
                    >
                        Metodologie
                    </Link>
                </div>

                <!-- Mobile Menu Button -->
                <div class="md:hidden">
                    <button
                        @click="toggleMobileMenu"
                        class="p-2 rounded-md text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-main"
                        :aria-expanded="mobileMenuOpen"
                    >
            <span class="sr-only">
              {{ mobileMenuOpen ? "Închide meniul principal" : "Deschide meniul principal" }}
            </span>
                        <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                :d="mobileMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile Menu -->
        <div
            v-if="mobileMenuOpen"
            class="md:hidden px-2 pt-2 pb-3 space-y-1"
            @click="toggleMobileMenu"
        >
            <Link
                v-for="(link, index) in links"
                :key="index"
                :href="link.href"
                class="block px-3 py-2 rounded-md text-sm font-medium hover:scale-105 transition-transform duration-200"
                :class="{ 'bg-main text-white': $page.url === link.href }"
                :aria-label="link.label"
            >
                {{ link.label }}
            </Link>
        </div>
    </nav>
</template>

<script>
import { Link } from "@inertiajs/vue3";

export default {
    components: {
        Link,
    },
    data() {
        return {
            mobileMenuOpen: false,
            links: [
                { href: "/", label: "Ştiri" },
                { href: "/politicieni", label: "Politicieni" },
                { href: "/publicatii", label: "Publicații" },
                { href: "/despre", label: "Despre" },
                { href: "/cum-functioneaza", label: "Metodologie" },
            ],
        };
    },
    methods: {
        toggleMobileMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen;
        },
    },
};
</script>
